import { FC, useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { Box, Button } from '@qwealth/qcore';

import {
  selectComplianceCheckslists,
  selectComplianceContact,
  selectComplianceHousehold,
  selectComplianceSelectedChecklist,
  selectComplianceSelectedChecklistNotes,
} from 'data/selectors/compliance';
import { useAppDispatch, useAppSelector } from 'data/store';
import {
  ChecklistSubmission,
  ComplianceNotesMap,
  ComplianceQuestion,
} from 'data/models/Compliance';
import {
  fetchComplianceHouseholds,
  setComplianceSelectedChecklist,
  setComplianceSelectedChecklistNotes,
  setComplianceSelectedContact,
  submitComplianceChecklist,
} from 'data/actions/compliance';
import ChecklistSection from './ChecklistSection';

const Checklist: FC = (): JSX.Element => {
  const checklists = useAppSelector(selectComplianceCheckslists);
  const selectedChecklist = useAppSelector(selectComplianceSelectedChecklist);
  const selectedContact = useAppSelector(selectComplianceContact);
  const selectedHousehold = useAppSelector(selectComplianceHousehold);
  const selectedChecklistNotes = useAppSelector(
    selectComplianceSelectedChecklistNotes,
  ) as ComplianceNotesMap;
  const [complianceChecklistAnswers, setComplianceChecklistAnswers] =
    useState<Record<string, string | null | undefined>>();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (checklists && selectedContact?.QID) {
      const selectedContactChecklist = checklists.find(list => {
        return list.contactQID === selectedContact.QID;
      });
      if (selectedContactChecklist) {
        dispatch(setComplianceSelectedChecklist(selectedContactChecklist.checklist));
        dispatch(
          setComplianceSelectedChecklistNotes(
            selectedContactChecklist?.notes as ComplianceNotesMap,
          ),
        );
      }
    }
  }, [checklists]);

  useEffect(() => {
    if (selectedChecklist) {
      setComplianceChecklistAnswers(getIdToAnswerMap(selectedChecklist));
    }
  }, [selectedChecklist]);

  const getIdToAnswerMap = (checklist: ComplianceQuestion[]) => {
    const result: Record<string, string | null | undefined> = {};
    if (!checklist) {
      return {};
    }
    for (const item of checklist) {
      if (!item.children || item.children.length === 0) {
        // All questions are preset to yes when the checklist is not started
        selectedContact?.complianceChecklistStatus === 'Not Started'
          ? (result[item.id.toString()] = 'yes')
          : (result[item.id.toString()] = item.value);
      } else {
        // If the node has children, recursively collect values from them
        const childValues = getIdToAnswerMap(item.children);
        Object.assign(result, childValues); // Merge all the values into a single map
      }
    }

    return result;
  };

  const updateQuestionAnswer = (questionId: string, option: string) => {
    if (complianceChecklistAnswers === undefined) {
      return;
    }
    const updatedSubmission = { ...complianceChecklistAnswers };
    updatedSubmission[questionId] = option;
    setComplianceChecklistAnswers(updatedSubmission);
  };

  const clickSubmit = async () => {
    const payload = {
      values: complianceChecklistAnswers,
      notes: Object.values(selectedChecklistNotes),
    };
    try {
      if (selectedContact?.QID === undefined || payload.values === undefined) {
        throw new Error('Unable to submit checklist, QID or answers is undefined');
      }
      await dispatch(
        submitComplianceChecklist(selectedContact?.QID, payload as ChecklistSubmission),
      );
    } catch (error) {
      console.error(error);
      return;
    }

    dispatch(setComplianceSelectedContact(undefined));
    dispatch(setComplianceSelectedChecklist([]));
    dispatch(setComplianceSelectedChecklistNotes({}));
    await dispatch(fetchComplianceHouseholds());
    navigate('/compliance/' + selectedHousehold?.QID);
  };

  return (
    <Box display="flex" flexDirection="column" padding="large" gap="large" maxHeight="100vh">
      {selectedChecklist &&
        selectedChecklist.map(section => {
          return (
            <ChecklistSection
              key={'ChecklistSection' + section.text}
              section={section}
              updateQuestionAnswer={updateQuestionAnswer}
            />
          );
        })}

      <Button onClick={() => clickSubmit()} minHeight="50px" fontSize="large">
        Submit Review
      </Button>
      <Box minHeight="500px" />
    </Box>
  );
};

export default Checklist;
