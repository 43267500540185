import { fetchKycWithQIDs } from '@qwealth/qdata';
import { ContactChecklist, ShortCode } from 'data/models/Compliance';

export const fetchKycMapWithEntities = async (
  contactChecklists: ContactChecklist[] | undefined,
) => {
  if (contactChecklists) {
    const qidList = contactChecklists.map(contactChecklist => contactChecklist.contactQID);
    const kycMapWithEntities = await fetchKycWithQIDs(qidList);
    return kycMapWithEntities;
  }
};

export const getComplianceStatusForQuestion = (value: string | null | undefined) => {
  switch (value) {
    case 'yes':
      return true;
    case 'n/a':
      return true;
    case 'no':
      return false;
    default:
      return undefined;
  }
};

export const getDestinationForShortCode = (
  shortCode: ShortCode,
  contactQID: string,
  householdQID: string,
): string => {
  switch (shortCode) {
    case 'valid_id':
      return `/contacts/${contactQID}?contactTab=bankingId`;
    case 'enhanced_kyc':
      return `/contacts/${contactQID}`;
    case 'employer':
      return `/contacts/${contactQID}`;
    case 'incomes':
      return `/contacts/${contactQID}`;
    case 'assets_liabilities':
      return `/contacts/${contactQID}?contactTab=financial`;
    case 'insider_status':
      return `/contacts/${contactQID}?contactTab=regulatory`;
    case 'domestic_foreign_pep':
      return `/contacts/${contactQID}?contactTab=regulatory`;
    case 'third_party':
      return `/contacts/${contactQID}?contactTab=regulatory`;
    case 'other_regulatory':
      return `/contacts/${contactQID}?contactTab=regulatory`;
    case 'rtq_notes':
      return `/household?household=${householdQID}&kycTab=rtq&householdTab=KYC`;
    case 'aml_risk':
      return `/household?household=${householdQID}&kycTab=rtq&householdTab=KYC`;
    case 'pm_mandate':
      return `/household?household=${householdQID}&kycTab=rtq&householdTab=KYC`;
    case 'sophistication':
      return `/household?household=${householdQID}&kycTab=rtq&householdTab=KYC`;
    case 'restrictions_unique_circumstances':
      return `/household?household=${householdQID}&kycTab=rtq&householdTab=KYC`;
    // Default value is the for Welcome letter / IMA / IPS -> QVault
    default:
      return `/household?household=${householdQID}&householdTab=qvault`;
  }
};

export const getSectionForShortCode = (shortCode: ShortCode, isLegalEntity: boolean): string => {
  switch (shortCode) {
    case 'onboarding':
      return isLegalEntity ? 'LegalEntityInfo' : 'DemographicInfo';
    case 'valid_id':
      return 'GovernmentId';
    case 'enhanced_kyc':
      return isLegalEntity ? 'LegalEntityInfo' : 'DemographicInfo';
    case 'employer':
      return 'EmploymentInfo';
    case 'incomes':
      return 'EmploymentInfo';
    case 'assets_liabilities':
      return 'FinancialInfo';
    case 'insider_status':
      return 'Regulatory';
    case 'domestic_foreign_pep':
      return 'Regulatory';
    case 'third_party':
      return 'Regulatory';
    case 'other_regulatory':
      return 'Regulatory';
    case 'enhanced_kyc_notes':
      return 'PMNotes';
    case 'rtq_notes':
      return 'PMNotes';
    case 'aml_risk':
      return 'RTQResponses';
    case 'pm_mandate':
      return 'RTQResponses';
    case 'sophistication':
      return 'RTQResponses';
    case 'restrictions_unique_circumstances':
      return 'RTQResponses';
    default:
      return 'Demographic Info';
  }
};
