import { FC } from 'react';
import { BsChevronRight } from 'react-icons/bs';
import moment from 'moment';
import { Box, styled, themeGet } from '@qwealth/qcore';
import CompletionCheckbox from '../Common/CompletionCheckbox';
import { ComplianceChecklistStatus } from 'data/models/Compliance';
import InfoTag from 'components/common/SectionHeader/InfoTag';

const isCompliantStatusMap: Record<ComplianceChecklistStatus, boolean | undefined> = {
  'Not Started': undefined,
  Complete: true,
  'Advisor Action Required': false,
  'Re-Review Required': undefined,
};

interface StyledHouseholdChecklistButtonProps {
  isCompliant: boolean | undefined;
}

const StyledHouseholdChecklistButton = styled.button<StyledHouseholdChecklistButtonProps>`
  width: 100%;
  background-color: ${props => (props.isCompliant ? '#E7F2DF' : themeGet('colors.gray.3'))};
  padding: ${themeGet('space.default')};
  border: none;
  border-radius: ${themeGet('radii.default')};
  font-size: ${themeGet('fontSizes.large')};
  font-weight: ${themeGet('fontWeights.bold')};
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  &:hover {
    background-color: ${props => (props.isCompliant ? '#E7F2DF' : themeGet('colors.gray.2'))};
    color: ${themeGet('colors.primaryLight.1')};
  }
`;

interface HouseholdChecklistButtonProps {
  name: string;
  checklistStatus: ComplianceChecklistStatus;
  ipsAcceptanceDate: string;
  onClick: () => void;
}

const HouseholdChecklistButton: FC<HouseholdChecklistButtonProps> = ({
  name,
  checklistStatus,
  ipsAcceptanceDate,
  onClick,
}): JSX.Element => {
  const ipsDate = moment(ipsAcceptanceDate).format('YYYY-MM-DD');
  return (
    <StyledHouseholdChecklistButton
      isCompliant={isCompliantStatusMap[checklistStatus]}
      onClick={onClick}
    >
      <Box display="flex" flexDirection="row" gap="large" alignItems="center">
        <CompletionCheckbox isCompliant={isCompliantStatusMap[checklistStatus]} />
        {name}
        <InfoTag tagTitle={checklistStatus} />
      </Box>
      <Box
        marginRight="default"
        marginTop="small"
        fontSize="default"
        display="flex"
        flexDirection="row"
        justifyContent="center"
        alignItems="center"
        gap="default"
      >
        <Box fontWeight="bold">IPS Date: </Box>
        <Box fontWeight="normal">{ipsDate}</Box>
        <BsChevronRight />
      </Box>
    </StyledHouseholdChecklistButton>
  );
};

export default HouseholdChecklistButton;
